.mainContainer {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  font-size: 64px;
  font-weight: bolder;
  align-items: center;
  justify-content: center;
}

.resultContainer, .historyItem { 
  flex-direction: row;
  display: flex;
  width: 400px;
  align-items: center;
  justify-content: space-between;
}

.historyContainer {
  flex-direction: column;
  display: flex;
  height: 200px;
  align-items: center;
  flex-grow: 5;
  justify-content: flex-start;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 260px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.errorLabel {
  color: red;
  font-size: 13px;
}

.inputBox {
  height: 48px;
  width: 400px;
  font-size: large;
  border-radius: 8px;
  border: 1px solid grey;
  padding-left: 8px;
}
.DashContainer {
  flex-direction: column;
  display: flex;
  align-items: center;
}
.headerText{
  font-size: 40px;
  font-weight: bolder;
  align-items: center;
}
.headerTextt{
  font-size: 20px;
  font-weight: bolder;
  align-items: center;
  padding-top: 20px;
}
.uploadStyles{
padding-top: 30px;
font-size: 18px;
}
.inputFile{
  padding-left: 20px;
  height: 35px;
}
.imageStyle{
  padding-left: 25px;
  background-size: 20px;
}
.inputContainer{
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgb(122, 122, 122);
  color: gray;
  border-radius: 5px;
  height: 30px;
  width: '50%';
}
.buttonStyle{
  background-color: green;
  color: white;
  border-color: green;
  border-radius: 5px;
  font-size: 20px;
}

.inputContainers{
  flex-direction:column;
  display: flex;
}
.Appss {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

table {
  border: 2px solid rgb(0, 0, 0);
  width: 800px;
  height: 200px;
  padding: 0px;
}

th {
  border: 1px solid;
  border-bottom: 1px solid black;
}

td {
  border: 1px solid;
  text-align: center;
}
.inputContainerrs{
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgb(122, 122, 122);
  color: gray;
  border-radius: 5px;
  height: 30px;
  width: '50%';
}
.navbar{
  position:fixed;
}